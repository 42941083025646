import { searchResults } from '../mocks/search.mock';
import { ProductSearchResultItem } from '../models/props/ProductSearchResultsListProps';

async function searchProduct(name: string): Promise<ProductSearchResultItem[]> {
    const results = searchResults.filter((item) => {
        return item.title.toLowerCase().indexOf(name) !== -1;
    });
    return await new Promise((resolve) => resolve(results));
}

export const SearchService = {
    searchProduct
};
