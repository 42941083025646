import { Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

type CatalogBoxProps = {
    endDate?: number;
};

export const CatalogBox = ({ endDate }: CatalogBoxProps) => {
    return (
        <Flex
            flexWrap="wrap"
            columnGap={2}
            alignItems="center"
            justifyContent={{
                sm: 'center'
            }}
            textAlign="center"
        >
            <Text>
                <FormattedMessage id="catalog-is-valid-until" />:
            </Text>
            <Flex
                alignItems="center"
                justifyContent={{
                    xs: 'center'
                }}
            >
                <Text fontWeight="semibold" mr={2}>
                    {endDate ? (
                        <FormattedDate
                            value={endDate}
                            timeZone="UTC"
                            dateStyle="short"
                            timeStyle="medium"
                        />
                    ) : (
                        '-'
                    )}
                </Text>
            </Flex>
        </Flex>
    );
};
