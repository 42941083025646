import {
    Box,
    Button,
    Container,
    Drawer,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    useDisclosure,
    useMediaQuery
} from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Classes from './header.module.scss';

import { topBarLoggedUserLinks, topBarUnLoggedUserLinks } from '@/data/menu';

import ButtonCart from '@/components/Header/ButtonCart';
import ButtonCartUnlogged from '@/components/Header/ButtonCartUnlogged';
import { HamburgerIcon, HeartIcon, MagnifierIcon, UserIcon } from '@/components/Icons';
import LanguageSelector from '@/components/Language/LanguageSelector';
import MobileMenu from '@/components/MobileMenu';
import { LinksNavigation } from '@/components/Navigation/LinksNavigation';
import { UnreadNotificationsCounter } from '@/components/Notifications/UnreadNotificationsCounter';
import { ProductSearch } from '@/components/Search/ProductSearch';
import ShoppingCart from '@/components/ShoppingCart';
import ShoppingCartUnlogged from '@/components/ShoppingCartUnlogged';
import SignIn from '@/components/SignIn/SignIn';
import { BrandLink } from '@/components/UI/Links/BrandLink';
import NavLink from '@/components/UI/Links/NavLink';
import { UserPanel } from '@/components/UserPanel/UserPanel';

import breakpoints from '@/../themes/betterstyle-theme/breakpoints';
import RoutePath from '@/constants/route-path';
import { PersonalData } from '@/models/api/ProfileInfo';
import { HeaderProps } from '@/models/props/HeaderProps';
import { ProductCategoryProps } from '@/models/props/ProductCategoryProps';
import { APP_STAGING } from '@/pages/_app';
import { CategoryService } from '@/services';

import { useApp } from '../../../context/appContext';
import { useCurrentUser } from '../../../context/currentUserContext';

export type DesktopProps = boolean | undefined;

export type PlacementProps = 'top' | 'bottom' | 'left' | 'right';

interface ScrollProps extends Omit<HeaderProps, 'scrolledLg' | 'scrollDirection'> {
    desktop: DesktopProps;
}

function HeaderTop(props: ScrollProps) {
    const { desktop } = props;
    const { showDisabledOrderingPopup, orderingAllowed } = useApp();
    const { data: session } = useSession();
    const user = session?.user as Pick<PersonalData, 'name' | 'surname'>;
    const { unreadNotificationsCounter } = useCurrentUser();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { basketLocked } = useShoppingCart();
    const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure();
    const [dir, setDir]: [PlacementProps, Dispatch<SetStateAction<PlacementProps>>] =
        useState<PlacementProps>('right');
    const [buttonId, setButtonId] = useState<string>('');
    const intl = useIntl();
    const router = useRouter();
    const [categories, setCategories] = useState<ProductCategoryProps[]>([]);
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });

    const getCategories = useCallback(async () => {
        const categories =
            APP_STAGING > 1
                ? await CategoryService.getAllCategories().catch((error) =>
                      // eslint-disable-next-line no-console
                      console.error('Cannot fetch categories', error)
                  )
                : [];
        setCategories(() => categories || []);
    }, []);

    const obj = useMemo(
        () => ({
            onClose,
            router,
            intl
        }),
        [onClose, router, intl]
    );

    const linksObj = useMemo(
        () => ({
            unLoggedUserLinks: topBarUnLoggedUserLinks,
            loggedUserLinks: topBarLoggedUserLinks
        }),
        []
    );
    const links = session ? linksObj.loggedUserLinks : linksObj.unLoggedUserLinks;

    const openDrawerHandler = (
        ev: React.MouseEvent<HTMLButtonElement>,
        placement: PlacementProps
    ) => {
        const target: HTMLButtonElement = ev.currentTarget as HTMLButtonElement;

        if (target) {
            setButtonId(target.id);
        }

        if (
            !orderingAllowed &&
            ['shopping-cart-unlogged', 'button-cart'].indexOf(target.id) !== -1
        ) {
            showDisabledOrderingPopup();
            return;
        }

        setDir(placement);
        onOpen();
    };

    const toggleProductSearch = useCallback(() => {
        onSearchOpen();
    }, [onSearchOpen]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    useEffect(() => {
        const handleRouteChange = () => {
            obj.onClose();
        };

        obj.router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            obj.router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [obj]);

    useEffect(() => {
        if (isDesktop) {
            onSearchClose();
        }
    }, [isDesktop, onClose, onSearchClose]);

    return (
        <Box pos="relative" zIndex={10} pointerEvents="auto" bgColor="grey.100">
            <Container maxWidth="full">
                <Flex
                    alignItems="center"
                    py={1}
                    transition="padding 300ms ease-out"
                    justifyContent={desktop ? 'space-between' : 'flex-start'}
                    minH={12}
                >
                    {!desktop && (
                        <>
                            <Button
                                bgColor="transparent"
                                p={2}
                                w="auto"
                                h="auto"
                                minW={0}
                                mr={1}
                                id="button-hamburger"
                                onClick={(ev) => openDrawerHandler(ev, 'left')}
                            >
                                <HamburgerIcon pointerEvents="none" />
                            </Button>
                            <BrandLink w="126px" transform="translateY(-5px)" />
                        </>
                    )}
                    {desktop && (
                        <Flex
                            alignItems="center"
                            gap={4}
                            display="flex"
                            className={Classes.navigation}
                        >
                            <LinksNavigation links={links} color="grey.main" />
                            <Box borderLeftWidth={1} borderLeftColor="grey.main" pl={4}>
                                <LanguageSelector />
                            </Box>
                        </Flex>
                    )}
                    <Flex
                        columnGap={desktop ? 4 : 2}
                        justifyContent="center"
                        ml={desktop ? 0 : 'auto'}
                    >
                        <Flex alignItems="center" gap={{ base: 0, sm: 4, md: 0 }}>
                            {!desktop && APP_STAGING > 1 && (
                                <Button onClick={toggleProductSearch} variant="ghost">
                                    <MagnifierIcon fontSize="1.125rem" />
                                </Button>
                            )}
                            {!session && <SignIn desktop={desktop} />}
                            {session && (
                                <Flex alignItems="center">
                                    <Button
                                        display="flex"
                                        variant="link"
                                        color="black"
                                        p={2}
                                        id="user-panel"
                                        width="fit-content"
                                        onClick={(ev) => openDrawerHandler(ev, 'right')}
                                    >
                                        <Box position="relative">
                                            <UserIcon
                                                w={5}
                                                h={5}
                                                mr={unreadNotificationsCounter ? 2 : 0}
                                            />
                                            {!!unreadNotificationsCounter && (
                                                <UnreadNotificationsCounter
                                                    fontSize="xs"
                                                    px={1}
                                                    py={0}
                                                    position="absolute"
                                                    top={-2}
                                                    right={0}
                                                />
                                            )}
                                        </Box>
                                        {desktop && (
                                            <Box as="span" pointerEvents="none" ml={1}>
                                                {user?.name} {user?.surname}
                                            </Box>
                                        )}
                                    </Button>
                                </Flex>
                            )}
                            {session?.user && (
                                <NavLink href={RoutePath.Favourites} display="block" p={2}>
                                    <HeartIcon pointerEvents="none" w={5} h={5} />
                                    {desktop && (
                                        <Box as="span">
                                            {' '}
                                            <FormattedMessage id="favorites" />
                                        </Box>
                                    )}
                                </NavLink>
                            )}
                        </Flex>
                        {session ? (
                            <ButtonCart
                                id="button-cart"
                                onClick={(ev) => !basketLocked && openDrawerHandler(ev, 'right')}
                            />
                        ) : (
                            <ButtonCartUnlogged
                                id="shopping-cart-unlogged"
                                onClick={(ev) => openDrawerHandler(ev, 'right')}
                            />
                        )}
                    </Flex>
                </Flex>
            </Container>
            <Drawer
                isOpen={isOpen}
                placement={dir}
                onClose={onClose}
                size={desktop ? 'md' : 'full'}
            >
                <DrawerOverlay
                    bgColor={{
                        base: 'white',
                        xl: 'blackAlpha.600'
                    }}
                />
                <DrawerContent maxH="unset" h="100%" boxShadow="none">
                    {buttonId === 'button-cart' && <ShoppingCart />}
                    {buttonId === 'shopping-cart-unlogged' && <ShoppingCartUnlogged />}
                    {buttonId === 'user-panel' && <UserPanel onRouteChange={() => onClose()} />}
                    {buttonId === 'button-hamburger' && <MobileMenu categories={categories} />}
                </DrawerContent>
            </Drawer>
            <Drawer isOpen={isSearchOpen && !isDesktop} onClose={onSearchClose} placement="top">
                <DrawerContent>
                    <DrawerCloseButton
                        top="50%"
                        transform="translateY(-50%)"
                        onClick={() => onSearchClose()}
                    />
                    <Box pl={{ base: 0, sm: 4 }} pr={12}>
                        <ProductSearch />
                    </Box>
                </DrawerContent>
            </Drawer>
        </Box>
    );
}

export default HeaderTop;
