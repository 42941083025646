import axios from 'axios';

import { LocaleProps } from '@/models/props/LocalizationProps';
import { AppService } from '@/services/AppService';
import { EnvironmentService } from '@/services/EnvironmentService';

const BANNERS_URL = EnvironmentService.getStrapiURL() + '/banners';

async function getBannersLogged(
    locale: LocaleProps,
    logged: 'showToNonLoggedInUser' | 'showToLoggedInUser'
) {
    const url = new URL(BANNERS_URL);
    const urlSearchParams = url.searchParams;

    urlSearchParams.set('locale', locale);
    urlSearchParams.set('filters[region][$containsi]', AppService.getInstanceRegion());
    urlSearchParams.set(`filters[${logged}][$eq]`, 'true');
    urlSearchParams.set('sort[0]', 'orderNumber');
    urlSearchParams.set('pagination[pageSize]', '4');
    urlSearchParams.append('populate', 'image');

    const config = {
        headers: {
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_TOKEN}`
        }
    };

    const { data } = await axios.get(url.toString(), config).catch((error) => {
        throw new Error(error || 'BannerService: Something went wrong!');
    });

    return data?.data;
}

export const BannerService = {
    getBannersLogged
};
