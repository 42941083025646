import { EnvironmentService } from '@/services/EnvironmentService';

export class StrapiUtilsService {
    public static updateUploadsUrl(url: string): string {
        const oneSlashPattern = new RegExp(/^\/{1}[^/]{1}.*$/);
        const startsWithOneSlash = oneSlashPattern.test(url);
        if (startsWithOneSlash) {
            url = EnvironmentService.getStrapiImageURL().concat(url);
        }
        return url;
    }
}
