export const searchResults = [
    {
        id: 1,
        productCode: '8582',
        title: 'Mocna taśma dwustronna',
        url: '#'
    },
    {
        id: 2,
        productCode: '8582',
        title: 'Minizraszacz z pompką',
        url: '#'
    },
    {
        id: 3,
        productCode: '8582',
        title: 'Miniorganizer',
        url: '#'
    },
    {
        id: 4,
        productCode: '8582',
        title: 'Samoprzylepna taśma dekoracyjna 3D',
        url: '#'
    },
    {
        id: 5,
        productCode: '8582',
        title: 'Etui z podajnikiem do worków na śmieci',
        url: '#'
    }
];
