import axios from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import { ContactForm } from '@/models/api/Contact';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { AppService } from '@/services/AppService';
import { EnvironmentService } from '@/services/EnvironmentService';

const BASE_ENDPOINT = EnvironmentService.getPublicApiURL();
const CONTACT_FORM_HANDLER_URI = '/api/directsales-site-web/contactForm';

async function getContact(locale: LocaleProps) {
    const url = new URL(
        `${EnvironmentService.getStrapiURL()}/contact-pages?locale=${locale}&filters[region][$containsi]=${AppService.getInstanceRegion()}&filters[contentId][$eq]=contact&populate=media`
    );

    const config = {
        headers: {
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_TOKEN}`
        }
    };

    const { data } = await axios.get(url.toString(), config).catch((error) => {
        throw new Error(error || 'ContactService: Something went wrong!');
    });

    return data?.data?.[0].attributes;
}

async function sendContactForm(data: ContactForm, opts?: OptionsWithServerSideRequest) {
    const headers = getAuthHeaders(opts);
    const url = new URL(`${BASE_ENDPOINT}/email/sendEmailToBok`);
    const subject = data.subject;
    const content = data.content;
    const email = data.email;
    const attachments = data.attachments;

    return axios.post<ContactForm>(
        url.toString(),
        {
            subject,
            content,
            email,
            attachments
        },
        { headers }
    );
}

async function verifyRecaptcha(token: string) {
    return axios.post(CONTACT_FORM_HANDLER_URI, { token }).then((res) => res.data);
}

export const ContactService = {
    getContact,
    sendContactForm,
    verifyRecaptcha
};
