import axios, { RawAxiosRequestHeaders } from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import {
    AddShippingProductRequestData,
    PaymentRequestData,
    RefundsProps
} from '@/models/props/PaymentDetailsProps';
import { BasketProps, BasketShippingProps, CartSummary } from '@/models/props/ProductCardProps';
import { ShippingProps } from '@/models/props/ShippingProps';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { EnvironmentService } from '@/services/EnvironmentService';

const BASE_ENDPOINT = `${EnvironmentService.getPublicApiURL()}/carts`;

const BASKET_HANDLER_URI = '/api/directsales-site-web/basketHandler';

const PRODUCT_HANDLER_URL = '/api/directsales-site-web/productsHandler';

const SHIPPING_HANDLER_URL = '/api/directsales-site-web/shippingHandler';

const PAYMENT_HANDLER_URL = '/api/directsales-site-web/paymentHandler';

async function getBasket(headers: RawAxiosRequestHeaders) {
    const url = new URL(BASE_ENDPOINT);

    const { data } = await axios.get(url.toString(), { headers });

    return data;
}

async function addToCart(code: ProductIdProps, quantity: number, headers: RawAxiosRequestHeaders) {
    const url = new URL(`${BASE_ENDPOINT}/products`);

    return axios
        .post(url.toString(), { code, quantity }, { headers })
        .then((response) => response.data)
        .catch((error) => {
            throw new Error(error || 'Add to cart: something went wrong!');
        });
}

async function getCurrentBasketHandler() {
    return axios.get(BASKET_HANDLER_URI).then((res) => res.data);
}

async function updateProduct(
    code: ProductIdProps,
    quantity: number,
    headers: RawAxiosRequestHeaders
) {
    const url = new URL(`${BASE_ENDPOINT}/products`);

    return axios
        .put(url.toString(), { code, quantity }, { headers })
        .then((response) => response.data);
}

async function updateQuantityHandler(code: ProductIdProps, quantity: number | string) {
    const data = await axios
        .put<BasketProps>(PRODUCT_HANDLER_URL, { code, quantity })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });

    return data;
}

async function deleteItemHandler(code: ProductIdProps) {
    return await axios
        .delete<BasketProps>(PRODUCT_HANDLER_URL, { params: { code } })
        .then((res) => res.data);
}

async function deleteProduct(code: ProductIdProps, headers: RawAxiosRequestHeaders) {
    const url = new URL(`${BASE_ENDPOINT}/products/${code}`);

    return axios
        .delete(url.toString(), {
            headers
        })
        .then((response) => response.data);
}

async function deleteBasketHandler() {
    return axios.delete(BASKET_HANDLER_URI).then((res) => res.data);
}

async function deleteBasket(opts?: OptionsWithServerSideRequest) {
    const headers = getAuthHeaders(opts);
    const url = new URL(BASE_ENDPOINT);

    const { data } = await axios
        .delete(url.toString(), { data: {}, headers })
        .then((res) => res.data);

    return data;
}

async function getPaymentUrl(): Promise<{ paymentLink: string }> {
    const url = new URL(`${BASE_ENDPOINT}/payment/uri`);
    const { data } = await axios.get(url.toString()).catch((error) => {
        throw new Error(error || 'Payment: cannot get payment URI');
    });

    return data;
}

async function getShipping(headers: RawAxiosRequestHeaders) {
    const url = new URL(`${BASE_ENDPOINT}/shipping`);

    const { data } = await axios.get<ShippingProps>(url.toString(), { headers }).catch((error) => {
        throw new Error(error || 'Shipping: something went wrong!');
    });

    return data;
}

async function getShippingHandler() {
    return axios.get<ShippingProps>(SHIPPING_HANDLER_URL).then((res) => res.data);
}

async function getPaymentMethod(headers: RawAxiosRequestHeaders) {
    const url = new URL(`${BASE_ENDPOINT}/payment`);

    const { data } = await axios.get(url.toString(), { headers });

    return data;
}

async function getPaymentHandler() {
    return axios.get(PAYMENT_HANDLER_URL).then((res) => res.data);
}

async function setPaymentHandler(data: PaymentRequestData) {
    return axios.post(PAYMENT_HANDLER_URL, { data }).then((res) => res.data);
}

async function setPaymentMethod(data: PaymentRequestData, headers: RawAxiosRequestHeaders) {
    const url = new URL(`${BASE_ENDPOINT}/payment`);

    return axios.post(url.toString(), data, { headers }).then((res) => res.data);
}

async function makeOrderHandler() {
    return axios.put(BASKET_HANDLER_URI).then((res) => res.data);
}

async function makeOrder(headers: RawAxiosRequestHeaders) {
    const url = new URL(`${BASE_ENDPOINT}/summary`);

    return await axios.put(url.toString(), {}, { headers }).then((res) => res.data);
}

async function setShippingProduct(
    data: AddShippingProductRequestData,
    opts?: OptionsWithServerSideRequest
) {
    const headers = getAuthHeaders(opts);
    const url = new URL(`${BASE_ENDPOINT}/payment/shipping-product`);

    return await axios.post(url.toString(), data, { headers }).then((res) => res.data);
}

async function setShippingAddress(
    id: number | undefined | null,
    opts?: OptionsWithServerSideRequest
) {
    const headers = getAuthHeaders(opts);
    const url = new URL(`${BASE_ENDPOINT}/shipping`);
    const data = {
        primaryAddressChosen: !id,
        otherAddressChosenId: id || null
    };
    return await axios
        .put<{ shipping: BasketShippingProps; summary: CartSummary }>(url.toString(), data, {
            headers
        })
        .then((res) => res.data);
}

async function removeShippingProduct(opts?: OptionsWithServerSideRequest) {
    const headers = getAuthHeaders(opts);
    const url = new URL(`${BASE_ENDPOINT}/payment/shipping-product`);

    return await axios.delete(url.toString(), { headers }).then((res) => res.data);
}

async function setRefunds(data: Partial<RefundsProps>, opts?: OptionsWithServerSideRequest) {
    const headers = getAuthHeaders(opts);
    const url = new URL(`${BASE_ENDPOINT}/controls`);
    const discountIncludeEnabled = data.discountIncludeEnabled;
    const refundIncludeEnabled = data.refundIncludeEnabled;

    return axios
        .put(url.toString(), { discountIncludeEnabled, refundIncludeEnabled }, { headers })
        .then((res) => res.data);
}

export const BasketService = {
    getBasket,
    getCurrentBasketHandler,
    addToCart,
    updateQuantityHandler,
    updateProduct,
    deleteItemHandler,
    deleteProduct,
    deleteBasketHandler,
    deleteBasket,
    getPaymentUrl,
    getShippingHandler,
    getShipping,
    getPaymentHandler,
    getPaymentMethod,
    setPaymentHandler,
    setPaymentMethod,
    makeOrderHandler,
    makeOrder,
    setShippingAddress,
    setShippingProduct,
    removeShippingProduct,
    setRefunds
};
